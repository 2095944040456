<template>
  <v-container fluid grid-list-lg class="pa-0">
    <SearchHeader :onAction="doSearch"></SearchHeader>

    <v-data-table :headers="headers" :items="users" :items-per-page="100" :search="search" class="elevation-1 ma-5">
      <template v-slot:item.state="{ item }">
        <v-icon v-if="item.state == 'active'" color="green" @click="setState(item.id, 'deactivate')"> mdi-account </v-icon>
        <v-icon v-else color="red" @click="setState(item.id, 'activate')"> mdi-account-off </v-icon>
      </template>
      <template v-slot:item.lastLoginDate="{ item }">
        {{ item.lastLoginDate | datetime }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon @click="pwdRequest(item)">mdi-account-key</v-icon>
        <v-icon @click="editItem(item)">mdi-account-edit</v-icon>
        <v-icon @click="deleteItem(item)">mdi-account-remove</v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("common.list.noResults") }}
      </template>
    </v-data-table>

    <CfgPanel ref="cfgpanel" :onSave="save" :title="$t('users.edit.title')" :tabIndex="7">
      <UserForm :user="user" format="full" ref="userForm" />
    </CfgPanel>
  </v-container>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin } from "@/helpers/mixins";

import CfgPanel from "@/components/_common/cfgpanel";
import SearchHeader from "@/components/_common/searchheader";
import UserForm from "./userform";

export default {
  mixins: [formatMixin, listMixin],

  data() {
    return {
      headers: [
        { text: this.$i18n.t("users.list.name"), align: "left", value: "name" },
        { text: this.$i18n.t("users.list.email"), align: "left", value: "email" },
        { text: this.$i18n.t("users.list.state"), align: "left", value: "state" },
        { text: this.$i18n.t("users.list.role"), align: "left", value: "role" },
        { text: this.$i18n.t("users.list.lastLoginDate"), align: "left", value: "lastLoginDate" },
        { text: this.$i18n.t("common.list.actions"), value: "action", sortable: false },
      ],
      user: {},
      users: [],
      editedIndex: -1,
      search: "",
    };
  },

  components: {
    CfgPanel,
    SearchHeader,
    UserForm,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },

  created() {
    this.loadUsers();
  },

  methods: {
    doSearch: function (val) {
      this.search = val;
    },

    loadUsers: function () {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/users?states=all`)
        .then((response) => {
          this.users = response.data;
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    setState: function (id, action) {
      axios
        .get(`/api/users/${id}/${action}`)
        .then(() => {
          this.loadUsers();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    editItem: function (item) {
      this.$refs.cfgpanel.show();
      var u = null;
      this.editedIndex = -1;
      this.user = {};

      if (item) {
        u = this.users.find((user) => {
          return user.id === item.id;
        });
        this.editedIndex = this.users.indexOf(u);
        if (this.editedIndex >= 0) {
          this.user = Object.assign({}, u);
        }
      }
    },

    pwdRequest: function (item) {
      if (confirm("Are you sure you want to send a password reset mail to this user?")) {
        axios
          .get(`/api/users/sendpwdresetrequest?email=${item.email}`)
          .then(() => {
            this.$store.dispatch("showSnackMessage", { message: "Password reset request has been setn" });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    deleteItem: function (item) {
      this.$router.push(`/admin/users/${item.id}`)
    },

    save: function () {
      if (this.$refs.userForm.$refs.form.validate()) {
        let u = this.$refs.userForm.getData();
        delete u.pwd2;
        delete u.lastLoginDate;
        delete u.createdAt;
        delete u.updatedAt;

        axios
          .post(`/api/users/${u.id || ""}`, u)
          .then((msg) => {
            this.updateItemList(this.users, msg.data);
            this.$refs.cfgpanel.hide();
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },
  },
};
</script>
