<template>
  <DefaultLayout>
    <template v-slot:header>
      <v-spacer />
      <AddButton :onClick="add" />
    </template>

    <v-layout>
      <v-flex>
        <UserManager ref="userManager" />
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
// @ is an alias to /src
import DefaultLayout from "@/layouts/default.vue";
import UserManager from "@/components/users/usermanager.vue";
import AddButton from "@/components/_common/addbutton";

export default {
  name: "usermngt",
  components: {
    DefaultLayout,
    UserManager,
    AddButton
  },
  methods: {
    add: function() {
      this.$refs.userManager.editItem();
    }
  }
};
</script>
