<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field v-model="user.name" :rules="rules.required" :label="$t('users.list.name')" required ref="firstfield" :tabIndex="10201"></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field v-model="user.email" :rules="rules.email" :label="$t('users.list.email')" required :tabIndex="10202"></v-text-field>
      </v-col>

      <template v-if="format == 'full'">
        <v-col cols="12" md="6">
          <v-select v-model="user.state" :items="data.states" :rules="rules.required" :label="$t('users.list.state')" required :tabIndex="10203"></v-select>
        </v-col>

        <v-col cols="12" md="6">
          <v-select v-model="user.role" :items="data.roles" :rules="rules.required" :label="$t('users.list.role')" required :tabIndex="10204"></v-select>
        </v-col>

        <v-col cols="12" md="6" v-if="!user.id">
          <v-switch v-model="user.sendPwdResetMail" label="Pwd mail an Benutzer"></v-switch>
        </v-col>
      </template>
      <v-col cols="12">
        <small>{{ $t("common.form.requiredText") }}</small>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { formatMixin, listMixin, formMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin, listMixin, formMixin],

  data() {
    return {
      data: {
        states: ["active", "inactive"],
        roles: ["user", "admin", "calview"],
      },
      rules: {},
      valid: false,
    };
  },

  props: {
    format: {
      type: String,
      default: () => "simple",
    },
    user: {
      type: Object,
      default: function() {
        return {
          name: "",
          email: "",
          state: "",
          role: "",
        };
      },
    },
  },

  components: {},

  computed: {},

  created() {},

  methods: {
    getData: function() {
      return this.user;
    },

    reset: function() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>
